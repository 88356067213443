import { initializeApp } from "firebase/app";
import * as geofirex from "../utils/plugins/geofirex";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  setDoc,
  addDoc,
  updateDoc,
  serverTimestamp,
  Timestamp,
  doc,
  query,
  where,
  onSnapshot,
  orderBy,
  increment,
  collectionGroup,
  arrayUnion,
} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  setPersistence,
  sendPasswordResetEmail,
  browserSessionPersistence,
} from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import { getAnalytics } from "firebase/analytics";
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

let firebaseConfig

if (
  window.location.hostname === "parishbulletin.org" ||
  window.location.hostname === "www.parishbulletin.org"
) {
  firebaseConfig = {
    apiKey: "AIzaSyD-i_bQoTsIO_9VYCGzZMJvB0yaqrshVV8",
    authDomain: "parishbulletin-82c8c.firebaseapp.com",
    databaseURL: "https://parishbulletin-82c8c.firebaseio.com",
    projectId: "parishbulletin-82c8c",
    storageBucket: "parishbulletin-82c8c.appspot.com",
    messagingSenderId: "865608647668",
    appId: "1:865608647668:web:284b366704f996af252fa1",
    measurementId: "G-7DR94MC0E8"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyDwM2YzUt8T7aLrgxEE9pHUBQoD2Wjk2ZY",
    authDomain: "parishbulletin-staging.firebaseapp.com",
    databaseURL: "https://parishbulletin-staging.firebaseio.com",
    projectId: "parishbulletin-staging",
    storageBucket: "parishbulletin-staging.appspot.com",
    messagingSenderId: "750630003712",
    appId: "1:750630003712:web:3aa94091a351a2ac4f64e5",
    measurementId: "G-2T4HQBWD1N",
  };
}

const app = initializeApp(firebaseConfig);
export const analyticsRef = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
export const parishRef = collection(db, "parish");
export const parishRequestRef = collection(db, "parishRequests");
export const countryRef = collection(db, "country");
export const dioceseRef = collection(db, "diocese");
export const massBookingsRef = collection(db, "massBookings");
export const massBookingRequestRef = collection(db, "massBookingsRequests");
export const usersRef = collection(db, "users");
export const parishClaimsRef = collection(db, "parishClaims");
export const jobApplicationRef = collection(db, "jobApplications");
export const geoFireX = geofirex.init(app);
export const parishGeoRef = geoFireX.query(parishRef);

export {
  db,
  getDoc,
  getDocs,
  setDoc,
  addDoc,
  updateDoc,
  doc,
  collection,
  collectionGroup,
  arrayUnion,
  auth,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  serverTimestamp,
  Timestamp,
  setPersistence,
  browserSessionPersistence,
  sendPasswordResetEmail,
  onAuthStateChanged,
  query,
  where,
  onSnapshot,
  orderBy,
  increment,
  storage,
  ref,
  uploadBytesResumable,
  uploadBytes,
  getDownloadURL,
  deleteObject,
};

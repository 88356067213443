import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import Home from "../views/Home.vue";
import Pricing from "../components/Pricing.vue";
import Addparish from "../components/Addparish.vue";
import Signup from "../components/Signup.vue";
import Login from "../components/Login.vue";
import Profile from "../components/Profile.vue";
import ResetPassword from "../components/ResetPassword.vue";
//import Map from '../components/GMap.vue'
import Parishes from "../components/Parishes.vue";
import Terms from "../components/Terms.vue";
import Privacy from "../components/Privacy.vue";
import Parishprogram from "../components/Parishprogram.vue";
import Bookdemo from "../components/Bookdemo.vue";
import Job from "../components/Job.vue";
import Claimparish from "../components/Claimparish.vue";
import Contactus from "../components/Contactus.vue";
import VerifyParishioner from "@/components/Verify-Parishioner.vue";
import MainOnboardingPage from "@/components/onboardParish/MainOnboardingPage.vue";
import SignUpScreen from "@/components/onboardParish/SignUpScreen.vue";
import LoginScreen from "@/components/onboardParish/LoginScreen.vue";
import SaveUserProfile from "@/components/onboardParish/SaveUserProfile.vue";
import RegisterNewParishioner from "@/components/onboardParish/RegisterNewParishioner.vue";
import {store} from "@/store";
import {useToast} from "vue-toastification";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: Home,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/pricing",
        name: "pricing",
        component: Pricing,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/jobs",
        name: "jobs",
        component: Job,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/signup",
        name: "signup",
        component: Signup,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/resetpassword",
        name: "resetpassword",
        component: ResetPassword,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/contactus",
        name: "contactus",
        component: Contactus,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/profile",
        name: "profile",
        component: Profile,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/parishes",
        name: "parishes",
        component: Parishes,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/parishprogram",
        name: "parishprogram",
        component: Parishprogram,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/terms",
        name: "terms",
        component: Terms,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/privacy",
        name: "privacy",
        component: Privacy,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/bookdemo",
        name: "bookdemo",
        component: Bookdemo,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/claimparish",
        name: "claimparish",
        component: Claimparish,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/addparish",
        name: "addparish",
        component: Addparish,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/verify-parishioner-details/:parishionerID',
        name: 'VerifyParishioner',
        component: VerifyParishioner,
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/onboard-your-parish',
        name: 'OnboardParish',
        component: MainOnboardingPage,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/onboarding-sign-up/:churchID',
        name: 'OnboardSignUp',
        component: SignUpScreen,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/onboarding-login/:churchID',
        name: 'OnboardLogin',
        component: LoginScreen,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/setup-user-profile/:churchID',
        name: 'SetupUserProfile',
        component: SaveUserProfile,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/church-member-registration',
        name: 'ChurchInvitationRegistration',
        component: RegisterNewParishioner,
        meta: {
            requiresAuth: false
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,

    scrollBehavior() {
        return {top: 0};
        // return {top: 0}
    },
});

router.beforeEach((to, from, next) => {
    const toast = useToast();
    const isLoggedIn = store.getters["user/is_loggedIn"];
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    if (requiresAuth && !isLoggedIn) {
        toast.info("Please Login");
        next({name: "login"});
    } else next();
});

export default router;
